import Router from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import AutoComplete, { InputProps, SuggestionHighlightedParams, SuggestionSelectedEventData, SuggestionsFetchRequestedParams } from 'react-autosuggest';
import { ServiceTypes } from '@infotrack/infotrackgo.web.core/enums/ServiceTypes';
import { AutoSuggester, AutoSuggestion } from '~/framework/AutoSuggester';
import { logger } from '@infotrack/infotrackgo.web.core/framework/logging/logger';
import { isEmpty } from '@infotrack/infotrackgo.web.core/framework/utils/AreUndefined';
import { SearchBarAutocompleteContent } from './SearchBarAutocompleteContent/SearchBarAutocompleteContent';
import { StateModal } from './StateModal';
import { State as StateEnum } from '@infotrack/infotrackgo.web.core/enums/State';
import { SearchBarSuggestionContainer } from './SearchBarSuggestionContainer/SearchBarSuggestionContainer';
import { SearchBarSuggestionContainerResult } from './SearchBarSuggestionContainerResult/SearchBarSuggestionContainerResult';
import { useElementSize } from '@infotrack/infotrackgo.web.core/framework/hooks/useElementSize';
import { ClassificationLabels } from '@infotrack/infotrackgo.web.core/enums/ClassificationLabels';
import { CancelTokenSource } from 'axios';
import { debounceAsync } from '@infotrack/infotrackgo.web.core/framework/utils/debounceAsync';
import { Constants } from '@infotrack/infotrackgo.web.core/framework/constants/constants';
import { isOnSearchDomain } from '@infotrack/infotrackgo.web.core/framework/environment/isOnSearchDomain';
import { getCurrentSearchDomain } from '@infotrack/infotrackgo.web.core/framework/environment/getCurrentSearchDomain';
import { objectToQueryString } from '@infotrack/infotrackgo.web.core/framework/requests/objectToQueryString';
import { classify } from '@infotrack/infotrackgo.web.core/api';
import { Classification, TitleSearchFilters } from '@infotrack/infotrackgo.web.core/models';
import { GAApi } from '~/framework/analytics/GAApi';
import { observer } from 'mobx-react-lite';
import { SearchBarAttachedFiltersContent } from './SearchBarAttachedFiltersContent/SearchBarAttachedFiltersContent';
import { useSearchStore } from '~/stores/search/context/SearchProvider';
import { SearchBarProps } from './SearchBarProps';
import { buildItgRouterArgs } from '~/framework/hooks/useItgRouterArgs';

const classifyInput = async (searchTerm: string, limitToServiceType?: ServiceTypes, cts?: CancelTokenSource): Promise<Classification[]> => {
    let classificationResponse = await classify(searchTerm, cts?.token);

    // If we don't get a classification
    if (!classificationResponse) {
        classificationResponse = [];
    }

    // If the user is searching from a service page
    if (limitToServiceType) classificationResponse = classificationResponse
        .filter(x => x.serviceType === limitToServiceType);

    return classificationResponse;
};

/**
 *  An autocomplete component designed to be a base from which search bar components may extend.
 *  See <HeaderSearchBar /> or <HomeSearchBar /> to see different integrations.
 */
export const SearchBar = observer(function SearchBarFunc(props: SearchBarProps) {
    const elementSize = useElementSize(props.uniqueId);
    const debouncedClassifyRef = useRef<any>(debounceAsync(classifyInput, Constants.DEBOUNCE.FASTEST));
    const { setVerticalSearchTerm, setVerticalClassification, buildItgRouterArgsQuery, selectedVertical, setVerticalFilters, getVerticaSearchTerm, getVerticalSearchForm } = useSearchStore();

    const [autoSuggestions, setAutoSuggestions] = useState<AutoSuggestion[]>([]);
    const [selectedSuggestion, setSelectedSuggestion] = useState<AutoSuggestion>();
    const [stateModalOpened, setStateModalOpened] = useState(false);
    const [showSuggestionList, setShowSuggestionList] = useState(false);
    const [searchBarRect, setSearchBarRect] = useState<DOMRect | null>(null);
    // set to open -> allow the user to see if any form values are required.
    const [showFiltersBox, setShowFiltersBox] = useState(false);

    // We do not show the innerFilters for the Property and Vehicle Verticals (allowing auto-complete suggestions)
    // so they do not overlap.
    const verticalsWithoutFilters = [ServiceTypes.Property, ServiceTypes.Vehicle];
    const definitiveShowInnerFilter = props.showInnerFilter && !verticalsWithoutFilters.includes(selectedVertical);

    // Listen on the width of the screen and save the bounding client rect.
    // this allows the <SearchBarSuggestionContainer /> width to match that of this component.
    useEffect(() => {
        const el = document.getElementById(props.uniqueId);
        const rect = el?.getBoundingClientRect() ?? null;
        setSearchBarRect(rect);
    }, [elementSize]);

    const setSearchTermOnChange = (searchTerm: string) => {
        setVerticalSearchTerm(searchTerm.trim());
    }

    /**
     * Very important method that handles final selection of an item.
     *
     * When a user selects and item from the search bar drop down, and then follows through
     * any modal that is shown, this method will be called.
     *
     * itemOnSelect will navigate the user to the /results page with the approproate args attached OR
     * if props.overrideItemOnSelect is provided it will offload the selection logic upstream.
     */
    const itemOnSelect = () => {
        const query = buildItgRouterArgsQuery();

        logger.info('User clicked on a search bar selection', { ...query });
        GAApi().event('search', {
            search_term: query.s,
            content_type: query.cat,
            value: {
                filters: query.args ? JSON.parse(query.args) : null,
                classification:query.label ? ClassificationLabels[query.label] : null
            }
        })
        

        setShowFiltersBox(false);

        if (props.overrideItemOnSelect) {
            props.overrideItemOnSelect(buildItgRouterArgs(query));
            return;
        }

        if (isOnSearchDomain()) {
            return Router.push({
                pathname: `/results`,
                query
            });
        }

        // Nav using location.href as react router will not be available in WP site.
        const goto = `${getCurrentSearchDomain()}/results${objectToQueryString({ ...query, referrer: 'WP' })}`;
        window.location.href = goto;
    };

    const getAutoSuggestionsForSearchTerm = async (searchTerm: string) => {
        try {
            const classifications = await debouncedClassifyRef.current(searchTerm, selectedVertical);

            // Cannot continue if no classifications came back
            if (!classifications?.length) return autoSuggestions;
            const autoSuggestionss = AutoSuggester(classifications, searchTerm);
            return autoSuggestionss;
        }
        catch {
            return autoSuggestions;
        }
    };

    // Only Allow suggestions for Property, or Vehicle(if is on head-search/filter icons shown)
    const allowSuggestions = Boolean(selectedVertical === ServiceTypes.Property || (props.showInnerFilter && selectedVertical === ServiceTypes.Vehicle));

    const onSearchTextEntered = (_: React.ChangeEvent<HTMLInputElement>, params: AutoComplete.ChangeEvent) => {
        setVerticalSearchTerm(params.newValue);
        setShowSuggestionList(allowSuggestions);
    };

    const onSelectedSuggestion = (value: string, item: AutoSuggestion) => {
        if (!item || !item.value || isEmpty(item.serviceType) || isEmpty(item.label)) return;

        // Set searchTerm and AutoSuggestion on VerticalSearch store
        setSearchTermOnChange(value);
        setVerticalClassification({ label: item.label, suggestId: item.identifier });
        // Note we only allow the popup state selection workflow for the Vehicle vertical on the Search HEADER. (props.showInnerFilter)
        // Search HOME completes with the VehicleSearchFormFilter instead
        if (item.label === ClassificationLabels.Property_TitleReference
        || (props.showInnerFilter && (item.label === ClassificationLabels.Vehicle || item.serviceType === ServiceTypes.Vehicle))) {
            setStateModalOpened(true);
            setSelectedSuggestion(item);
            setShowSuggestionList(false);

            // We return here because we are waiting for the `onStateModalOkClicked` function to
            // call the next part.
            return;
        }

        itemOnSelect();
    };

    const suggestionOnHighlighted = (params: SuggestionHighlightedParams) => {
        const suggestion: AutoSuggestion = params.suggestion;
        if (!params.suggestion) return;
        setSelectedSuggestion(suggestion);
    };

    const searchButtonOnClicked = () => {
        let isVerticalFormValid: boolean = true;
        if (selectedVertical !== ServiceTypes.Property) {
            let currentVerticalForm = getVerticalSearchForm(selectedVertical);
            isVerticalFormValid = !!currentVerticalForm?.isValid;
        }

        if (!isVerticalFormValid) return;
        // Company and Person skip suggestion proccess
        if (selectedVertical === ServiceTypes.Company
            || selectedVertical === ServiceTypes.Person) {
                return itemOnSelect();
        }

        let firstItemInDropdown = selectedSuggestion;

        if (autoSuggestions.length > 0) firstItemInDropdown = autoSuggestions[0];
        if (!firstItemInDropdown) return;

        onSelectedSuggestion(firstItemInDropdown.value, firstItemInDropdown);
    };

    const fetchSuggestions = async (request: SuggestionsFetchRequestedParams) => {
        const suggestions = await getAutoSuggestionsForSearchTerm(request.value);

        // Cant progress if there are no suggestions.
        // Set autoSuggestions to empty and selectedItem to undefined, because there is nothing to be selected
        if (!suggestions || !suggestions.length) {
            setAutoSuggestions([]);
            setSelectedSuggestion(undefined);
            return;
        }

        let firstSuggestion = suggestions[0];
        setAutoSuggestions(suggestions);
        setSelectedSuggestion(firstSuggestion);
    };

    // Only allow suggestion list for the Property Vertical
    const onClickInput = () => {
        if (selectedVertical === ServiceTypes.Property) setShowSuggestionList(true);
    }

    const suggestionOnSelected = async (e: React.FormEvent<any>, data: SuggestionSelectedEventData<AutoSuggestion>) => {
        await onSelectedSuggestion(data.suggestionValue, data.suggestion);
    };

    const stateModalOnClicked = (state?: StateEnum) => {
        // The modal has been closed
        if (typeof state !== 'string') {
            setStateModalOpened(false);
            return;
        }

        if (!selectedSuggestion) return;

        setStateModalOpened(false);
        setAutoSuggestions([]);

        const titlesearchArgs: TitleSearchFilters = {
            state: state
        };

        setVerticalFilters(titlesearchArgs);
        itemOnSelect();
    };

    const suggestionItems = showSuggestionList ? autoSuggestions : [];
    const isSuggesting = Boolean(showSuggestionList && getVerticaSearchTerm(selectedVertical).length);

    const getAutocompleteClassnames = () => {
        if (typeof props.getAutocompleteClassnames !== 'function') return '';
        return props.getAutocompleteClassnames(isSuggesting);
    };

    const getAutocompleteSuggestionContainerClassnames = () => {
        if (typeof props.getAutocompleteSuggestionContainerClassnames !== 'function') return '';
        return props.getAutocompleteSuggestionContainerClassnames();
    };

    const leftContent = (
        typeof props.renderLeftContent === 'function'
            ? props.renderLeftContent(isSuggesting)
            : null
    );

    const buttonContent = (
        typeof props.renderButtonContent === 'function'
            ?   props.renderButtonContent()
            :   null
    );

    return (
        <div
            id={props.uniqueId}
            className={props.className}
        >
            <StateModal open={stateModalOpened} onClose={stateModalOnClicked} name={getVerticaSearchTerm(selectedVertical) ?? ''} />
            <AutoComplete
                suggestions={suggestionItems}
                onSuggestionsFetchRequested={fetchSuggestions}
                onSuggestionsClearRequested={() => {
                    setShowSuggestionList(false);
                    setAutoSuggestions([]);
                }}
                getSuggestionValue={(item: AutoSuggestion) => item.value}
                onSuggestionSelected={suggestionOnSelected}
                onSuggestionHighlighted={suggestionOnHighlighted}
                inputProps={{
                    value: getVerticaSearchTerm(selectedVertical) ?? '',
                    onChange: onSearchTextEntered
                }}
                renderSuggestion={(suggestion, params) => (
                    <SearchBarSuggestionContainerResult
                        suggestion={suggestion}
                        params={params}
                    />
                )}
                renderSuggestionsContainer={(params) => (
                    <SearchBarSuggestionContainer
                        className={getAutocompleteSuggestionContainerClassnames()}
                        children={params.children}
                        containerProps={params.containerProps}
                        params={params}
                        searchBarRect={searchBarRect}
                    />
                )}
                renderInputComponent={
                    (inputProps: InputProps<any>) => (
                        <SearchBarAutocompleteContent
                            className={getAutocompleteClassnames()}
                            inputProps={inputProps}
                            onClickInput={onClickInput}
                            onSearchButtonClicked={searchButtonOnClicked}
                            buttonContent={buttonContent}
                            searchButtonContentClassname={props.searchButtonContentClassname}
                            filterButtonContentClassname={props.filterButtonContentClassname}
                            isSuggesting={isSuggesting}
                            leftContent={leftContent}
                            showFilterBtn={definitiveShowInnerFilter}
                            onFilterButtonClicked={() => setShowFiltersBox(!showFiltersBox)}
                        />
                    )
                }
            />
            {definitiveShowInnerFilter && (
                <SearchBarAttachedFiltersContent
                    className={props.innerFiltersContainerClassnames}
                    searchBarRect={searchBarRect}
                    customStyle={showFiltersBox ? { } : { display: 'none' }}
                />
            )}
        </div>
    );
})
