import { ServiceTypes } from '@infotrack/infotrackgo.web.core/enums/ServiceTypes';

export const getSearchBarPlaceholderText = (serviceType: ServiceTypes): string => {
    switch (serviceType) {
        case ServiceTypes.Property:
            return 'Type a property address, lot/plan or vol/fol';
        case ServiceTypes.Company:
            return 'ACN, ABN, Company or Business Name';
        case ServiceTypes.Person:
            return `Enter person's full name, e.g. Jane Doe or John Smith`;
        case ServiceTypes.Vehicle:
            return 'VIN/Chassis number';
        default:
            return 'Start your search here';
    }
};