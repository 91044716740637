import { frontEndLogger } from '@infotrack/infotrackgo.web.core/framework';
import { FormikErrors } from 'formik';
import { useEffect, useState } from 'react';

interface Props<T> {
    onChange: (value: T, errors: FormikErrors<T>, isValid: boolean) => void;
    value: T;
    errors: FormikErrors<T>;
    setErrors: (errors: FormikErrors<T>) => void;
    validateForm?: (values?: T) => Promise<FormikErrors<T>>
}

// A react component allowing you to listen on to formik form value changes...
export function FormikObserver<T>(props: Props<T>) {
    const newValues = JSON.stringify(props.value);
    const newErrors = JSON.stringify(props.errors);
    const [oldValuesAndErrors, setOldValuesAndErrors] = useState<string[]>([newValues, newErrors]);

    useEffect(() => {
        const [oldValues, oldErrors] = oldValuesAndErrors;
        // If the values are the same we have already updated, then return.
        if (oldValues === newValues && oldErrors === newErrors) return () => {};
        // We can not entirely rely on the isValid from formik.
        const isValid = Object.keys(props.errors).length < 1;

        const validateFormAsync = async()  => {
            props.onChange(
                props.value,
                props.errors,
                isValid
            );
        };
        validateFormAsync();
        setOldValuesAndErrors([newValues, newErrors]);
    }, [newValues, newErrors]);
    return null;
}

// Default prop.
FormikObserver.defaultProps = {
    onChange: () => null
};

export function FormikObserver2<T>(props: Props<T>) {
    const newValues = JSON.stringify(props.value);
    const newErrors = JSON.stringify(props.errors);
    const [oldValuesAndErrors, setOldValuesAndErrors] = useState<string[]>([newValues, newErrors]);

    useEffect(() => {
        const [oldValues, oldErrors] = oldValuesAndErrors;
        // If the values are the same we have already updated, then return.
        if (oldValues === newValues && oldErrors === newErrors) return () => {};
        // We can not entirely rely on the isValid from formik.
        const isValid = Object.keys(props.errors).length < 1;

        let validationErrors: FormikErrors<T> | null = null;
        if (props.validateForm) {
            props.validateForm()
                .then(errors => {
                    // Update the Form Error state
                    if (errors) {
                        props.setErrors(errors);
                        validationErrors = errors;
                    }
                })
                .catch(catchErr => frontEndLogger.error('Error validating form values', { newValues, ...catchErr }));
        }
        const validateFormAsync = async()  => {
            props.onChange(
                props.value,
                validationErrors ?? props.errors,
                isValid
            );
        };
        validateFormAsync();
        setOldValuesAndErrors([newValues, newErrors]);
    }, [newValues, newErrors]);
    return null;
}

// Default prop.
FormikObserver.defaultProps = {
    onChange: () => null
};
