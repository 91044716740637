import { TestContext, TestFunction, object, string } from "yup";
import { AnyObject } from "yup/lib/types";

const yearRangeCustomValidation = (): TestFunction<string | undefined, AnyObject> => (value: string | undefined, ctx: TestContext) => {
    return new Promise((resolve, reject) => {
        if (!value) return resolve(true);

        // Regex allowing only digits, whitespaces and hypens.
        let regex = new RegExp(/^[\d\s-]+$/);
        const passes = regex.test(value ?? '');
        if (!passes) return reject(ctx.createError({message: 'The year range contains invalid characters'}));;

        const values = value.trim().split('-');
        const negativeValues = values.filter(y => +y <= 0);
        if (negativeValues.length > 0) reject(ctx.createError({message: 'Please enter a non-negative value.'}));
        if (+values[1] < +values[0])  reject(ctx.createError({message: 'The end year must be greater than the start year'}));

        return resolve(true);
    });
}

export const personSearchFormValidation = object().shape({
    yearRangeInput: string()
        .test({
            name: 'YearRangeValidation',
            test: yearRangeCustomValidation()
        })
});
